import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

const Dashboard = () => {
  const admin1 = [
    { main: 'Lecture-1', additional: '1030 - 1100' },
    { main: 'Tea Break', additional: '0900 - 1030' },
    { main: 'Lecture - 2', additional: '1100 - 1230' },
    { main: 'Prayer & Lunch Break', additional: '1230 - 1330' },
    { main: 'Lecture - 3', additional: '1330 - 1500' },
    { main: 'Online Class', additional: '15:15 - 1600' },
  ];
  const admin2 = [
    { main: '100%', additional: 'Attendence', color: '#72b38a' },
    { main: '95%', additional: 'Assignments', color: '#c1c9c4' },
    { main: '90%', additional: 'Quiz', color: '#c1c9c4' },
    { main: '100%', additional: 'Tasks', color: '#72b38a' },
    { main: '100%', additional: 'Feedback', color: '#72b38a' },
    { main: '97%', additional: 'Overall', color: '#c1c9c4' },
  ];
  return (
    <div className='w-full bg-white'>
      <Header />
      <div className='flex'>
      <div className='w-[20%]'>
        <Sidebar />
      </div>
      <div className='p-1 []'>
        <div className='flex flex-col'>
          <div className='w-full text-xl font-bold text-center text-black bg-orange-300'>
            Daily Schedule
          </div>
          <div className="flex mt-1">
            <div className='grid w-full h-auto grid-cols-6 md:grid-cols-6 md:grid-rows-1 gap-x-2'>
              {admin1.slice(0).map((divContent, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-between font-bold text-center border border-gray-900 rounded">
                  <div className="content-center w-full text-xs font-bold text-black md:text-2xl h-1/2">{divContent.main}</div>

                  {divContent.additional && <div className="content-center w-full px-1 text-xs text-black bg-orange-200 md:text-base bg-primarySelected h-1/3 md:px-0">{divContent.additional}</div>}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col mt-1 h-80'>
          <div className='bg-blue-500 h-80'>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='w-full text-xl font-bold text-center text-white bg-blue-900'>
            Progress Todate
          </div>
          <div className="flex mt-1">
            <div className="grid w-full h-auto grid-cols-6 md:grid-cols-6 md:grid-rows-1 gap-x-2">
              {admin2.map((divContent, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-between font-bold text-center border border-gray-900 rounded"
                >
                  <div
                    className="content-center w-full pt-1 text-xs font-bold text-black md:text-2xl h-1/2"
                    style={{ backgroundColor: divContent.color }} 
                  >
                    {divContent.main}
                  </div>

                  {divContent.additional && (
                    <div className="content-center w-full px-1 pt-2 text-xs text-black bg-green-200 md:text-xl bg-primarySelected h-1/2 md:px-0">
                      {divContent.additional}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}
export default Dashboard
