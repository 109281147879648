import React from 'react';
import ReactApexChart from 'react-apexcharts';

class ServiceGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [20, 25, 19, 22, 45, 98, 35, 133],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          },
        },
        colors: ['#884c9c'],
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#fff'],
            fontSize: '16px', 
            fontWeight: 'bold',
          },
          formatter: function (val) {
            return val;
          },
          offsetX: -2,
        },
        xaxis: {
          categories: [
            'Above 30 Y',
            '20 - 30 Y',
            '15 - 20 Y',
            '11 - 15 Y',
            '3 - 5 Y',
            '< 3 Year',
            'Fresh Induction',
          ],
          labels: {
            style: {
              fontSize: '0px', 
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px', 
              fontWeight: 'bold',
              colors: ['#000'],
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ServiceGraph;
