import React from 'react';
import ReactApexChart from 'react-apexcharts';

class FormationsGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [9, 7, 5, 18, 19, 11, 10, 28],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          },
        },
        colors: ['#9c2f46'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontWeight: 'bold',
            colors: ['#fff'],
          },
          formatter: function (val) {
            return val;
          },
          offsetX: 10,
        },
        xaxis: {
          categories: [
            'IRRIGATION GB',
            'PEDO AJK',
            'PDO AJK',
            'NIDU',
            'DISCOs',
            'GENCO',
            'Japan',
            'WAPDA (All Wings)',
          ],
          labels: {
            style: {
              fontSize: '0px',
              colors: ['#fff'],
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
            },
          },
        },
        tooltip: {
          theme: 'light', 





























































































































































































































































































































































































































































































































































































































































































































































































































































          
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#000',
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default FormationsGraph;
