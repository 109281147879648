import React from 'react'
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';

const ViewLink = ({ setUpdateData, handleButtonClick }) => {
  const allProgram = useSelector(state => state.allProgram);

  const handleEditDepartment = (prog) => {
    setUpdateData(prog);
    handleButtonClick('create');
  };

  return (
    <div className='flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg style-scrollbar lg:max-h-full lg:overflow-auto max-h-auto'>
      <div className="join join-vertical 
      w-full bg-SecondaryColor px-[2vw] py-[4vh] flex flex-col items-start justify-center gap-y-[2vh]
      text-black text-sm shadow-2xl">
        {allProgram && allProgram.map((prog, index) => (
          <div className="border collapse collapse-arrow join-item border-PrimaryDarkColor">
            <input type="radio" name="my-accordion-4" defaultChecked />
            <div className="flex justify-between font-medium collapse-title text-md text-PrimaryColor text-bold">
              <label className='w-[75%] text-start'>{`${index + 1}) ${prog.programName}`}</label>
              <label className={`w-[5%] text-start ${prog.status ? "text-green-600" : "text-red-600"}`}>{prog.status ? "Active" : "In-Active"}</label>
              <button className={`w-[5%] text-center z-10`} onClick={() => {
                if (prog.programCourses && prog.programCourses.length !== 0) {
                  handleEditDepartment(prog);
                }
              }}>
                {prog.programCourses && prog.programCourses.length !== 0 && <EditButton />}
              </button>

            </div>
            <div className="flex flex-col collapse-content">
              {prog.programCourses ? (
                Object.entries(prog.programCourses).map(([semester, courses]) => (
                  <div key={semester}>
                    {/* <h4 className='font-semibold text-md'>{semester}</h4> */}
                    {courses.length === 0 ? (
                      <p className='font-semibold text-red-600 text-md'>No courses available for this semester</p>
                    ) : (
                      <ul className='flex gap-x-[1vw]'>
                        {courses.map((course, index) => (
                          <li key={index} className='text-sm font-medium'>{`${index + 1}) ${course}`}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))
              ) : (
                <p>No courses linked for this program</p>
              )}
            </div>

          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewLink