import React from 'react';
import ReactApexChart from 'react-apexcharts';

class QualificationGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: [29, 25, 20, 66, 186, 65],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: 'end',
            horizontal: true,
          },
        },
        colors: ['#ad4834'],
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#fff'], 
            fontSize: '16px',
            fontWeight: 'bold',
          },
          formatter: function (val) {
            return val; 
          },
          enabled: true,
          offsetX: 10,
          textAnchor: 'end', 
        },
        xaxis: {
          categories: [
            'Post Doctorate',
            'PhD',
            'M Phil',
            'Masters',
            'Engg. Graduate',
            'Graduate',
          ],
          labels: {
            style: {
              colors: ['#000'], 
              fontSize: '0px',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '14px', 
              fontWeight: 'bold',
              colors: ['#000'],
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default QualificationGraph;
