import React from 'react';
import {useSelector} from 'react-redux';
import config from '../../../config';

const Header = () => {
  const backendBaseUrl = config.backendLocalhost;
  const configName = useSelector(state => state.configName);

  return (
    <header className='w-full h-[10vh] flex bg-blue-300 rounded-b-[1vw]'>
      <div className='w-[10%] h-full p-[1vw] flex justify-center items-center'>
        <img
          src={`${backendBaseUrl}/ConfigurtionFiles/${configName[0]?.nameConfiguration?.Logo}`}
          alt=''
          className='w-[8vw] h-[4vw]'
        />
      </div>
      <div className='w-[80%] h-full flex justify-center items-center'>
        <p className='text-3xl font-bold'>{configName[0]?.nameConfiguration.Title}</p>
      </div>
      <div className='w-[10%] h-full'>
      </div>
    </header>
  );
};

export default Header;