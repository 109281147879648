import { useEffect, useState } from "react";
import men from '../../assets/images/men.jpg';
const Header = () => {

    return (
        <header className="sticky top-0 flex w-full bg-green-100 z-999 dark:bg-boxdark dark:drop-shadow-none">
            <div className="flex items-center justify-between flex-grow px-4 py-1 rounded shadow-2 md:px-6 2xl:px-11">
                <div className="flex items-end justify-end">
                    <div className="items-center justify-center w-20 h-20 -mr-4 bg-purple-900 rounded-full">
                        <p className="flex justify-center mt-2 text-2xl text-center text-white">TMS 360</p>
                    </div>
                    <div className="items-end justify-end w-40 h-12 bg-blue-800 rounded-md">
                        <p className="flex justify-center mt-2 text-2xl text-center text-white">Trainee Portal</p>
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center flex-1 text-xs font-semibold text-center text-blue-700 md:text-3xl">
                    <p>WAPDA Administrative Staff College</p>
                    <p>Islamabad</p>
                </div>
                <div className="items-center md:flex gap-x-2">
                    <div className="flex flex-col items-start justify-center text-xs text-black md:text-base">
                        <p className="text-xl">Engr. Abdul Ahad</p>
                        <p className="text-md">Director (Admin)</p>
                        <p className="text-md">LESCO, Lahore</p>
                    </div>
                    <div className="w-16 h-15">
                        <img src={men} className="" alt="Flag" />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
