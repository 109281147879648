import React from "react";
import { Chart } from "react-google-charts";

const data = [
  ["Name", "Percentage"],
  ["BPS-20", 0.09],
  ["BPS-16", 0.13],
  ["BPS-19", 0.13],
  ["BPS-18", 0.21],
  ["BPS-17", 0.44],
];

const options = {
  legend: "none",
  pieSliceText: "percentage",
  pieStartAngle: 100,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  tooltip: { trigger: "focus" },
  chartArea: { width: "70%", height: "70%" },
  colors: ["#ad4834", "#7fc25f", "#44a9c2", "#884c9c", "#3181b0"],
  pieSliceTextStyle: {
    fontSize: 18, 
    bold: true,
  },
};

function BpsGraph() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"365px"}
    />
  );
}

export default BpsGraph;
