import React from 'react';
import { MainGraph } from '../DashboardGraph/MainGraph';

const Sidebar = () => {
  return (
    <div className='p-1 [20%]'>
      <div className="md:w-[100%] mt-2 md:mt-0 md:grid md:grid-rows-1 md:gap-x-3 gap-x-1 bg-green-100 px-1">
        <div className="flex flex-col items-center justify-between text-xs font-bold text-center border border-gray-900 rounded md:text-base">
          <div className="w-full pt-3 text-xs font-bold text-black md:pt-0 md:text-2xl h-1/3">SMC-198</div>
          <div className="w-full text-white bg-purple h-1/3 md:text-2xl text-[10px] bg-red-500 pt-1">NOV</div>
          <div className="w-full text-black bg-primarySelected md:text-5xl text-[8px] h-1/2 pt-1">11</div>
        </div>
      </div>
      <div className="pt-5">
        <div className="px-1 ">
          <button className="left-0 right-0 block w-full text-xl font-bold text-black text-start">
            Registration
          </button>
        </div>
        <div className='pt-5'>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Pending Task
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Notifications
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Chat
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Events
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Course Material
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Volunteer Tasks
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Result
          </button>
          <button className="left-0 right-0 block w-auto px-1 mt-2 text-xl font-bold text-black text-start">
            Payment History
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
