import React from 'react';
import ReactApexChart from 'react-apexcharts';

class TrainingGraph extends React.Component {
  state = {
    series: [
      {
        data: [104, 81, 49, 29],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4, 
          horizontal: true,
        },
      },
      colors: ['#ba952f'],
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#fff'],
          fontSize: '16px',
          fontWeight: 'bold',
        },
        formatter: function (val) {
          return val;
        },
        offsetX: -10,
        textAnchor: 'start',
      },
      xaxis: {
        categories: ['OTHERS', 'JMC', 'MMC', 'SMC'],
        labels: {
          style: {
            fontSize: '0px',
            colors: ['#fff'],
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
        },
      },
    },
  };

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default TrainingGraph;
