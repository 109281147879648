import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import FullPieGraph from '../../DashboardGraph/FullPieGraph';
import TrainingGraph from '../../DashboardGraph/TrainingGraph';
import BpsGraph from '../../DashboardGraph/BpsGraph';
import AgeGraph from '../../DashboardGraph/AgeGraph';
import GenderGraph from '../../DashboardGraph/GenderGraph';
import FormationsGraph from '../../DashboardGraph/FormationsGraph';
import ServiceGraph from '../../DashboardGraph/ServiceGraph';
import QualificationGraph from '../../DashboardGraph/QualificationGraph';
const Dashboard = () => {
    const fields = [
        {
            name: "Year",
            width: 'md:w-[8%]',
        },
        {
            name: "Training Program",
            width: 'md:w-[20%]',
        },
        {
            name: "Formation",
            width: 'md:w-[20%]',
        },
        {
            name: "Gender",
            width: 'md:w-[8%]',
        },
        {
            name: "Qualification",
            width: 'md:w-[15%]',
        },
        {
            name: "Age",
            width: 'md:w-[8%]',
        },
        {
            name: "Service Length",
            width: 'md:w-[20%]',
        }
    ];
    const additionalDivs = [
        { main:325, additional: 'NOMINATIONS', color:'bg-blue-800', text:'text-white'},
        { main:263, additional: 'JOINED', color:'bg-yellow-500'},
        { main:62, additional: 'NON JOINED', color:'bg-red-500',text:'text-white'},
        { main:172, additional: 'COMPLETED', color:'bg-green-500',text:'text-white'},
        { main:91, additional: 'UNDER TRAINING', color:'bg-gray-500',text:'text-white'},
      ];
    return (
        <div className='w-full h-full p-1 overflow-auto bg-white'>
            <div className='w-full text-black'>
                <div className="flex flex-col space-x-0 md:flex-row md:space-x-1">
                    {fields.map((field, index) => (
                        <div key={index} className={`flex flex-col ${field.width}`}>
                            <label className="text-xl">{`${field.name}`}</label>
                            <select
                                className="py-2 text-lg text-left bg-green-100 border border-gray-900"
                            >
                            </select>
                        </div>
                    ))}

                    <div className='w-[30%] flex items-center justify-between 
  md:w-[10%]'>
                        <button className="mt-6 text-4xl text-black"

                        >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                        <button className="mt-6 text-4xl text-black">
                            <FontAwesomeIcon icon={faDownload} />
                        </button>
                    </div>
                </div>
                <p className='border bg-orange-400 p-0.5 mt-1 w-full'></p>
            </div>
            <div className="w-full mt-1 space-x-1 md:flex md:space-x-2">
                <div className='grid-cols-7 mt-2 md:w-full md:grid md:mt-0 md:grid-rows-1 md:gap-x-1 gap-x-1'>
                    {additionalDivs?.map((divContent, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center col-span-1 text-2xl font-bold text-center text-black border border-gray-900 rounded">
                            <div className="content-center w-full pt-3 text-xs font-bold h-3/4 md:pt-3 md:text-5xl">{divContent.main}</div>
                            {divContent.additional && <div className={`content-center w-full text-black h-2/4  ${divContent.color} ${divContent.text} md:text-base text-[10px]`}>{divContent.additional}</div>}
                        </div>
                    ))}
                    <div className='items-start justify-start col-span-2 border border-gray-900 rounded '>
                        <FullPieGraph />
                    </div>
                </div>
            </div>
            <div>
                <div className='grid-cols-3 mt-1 md:grid gap-x-1'>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            FORMATIONS
                        </div>
                        <div className='items-start justify-start col-span-2 mt-2 border border-gray-900 '>
                            <FormationsGraph />
                        </div>
                    </div>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            GENDER
                        </div>
                        <div className='flex items-center justify-center col-span-2 mt-2 border border-gray-900 '>
                            <GenderGraph />
                        </div>
                    </div>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            TRAINING PROGRAMS
                        </div>
                        <div className='items-start justify-start col-span-2 mt-2 border border-gray-900 '>
                            <TrainingGraph />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-1'>
                <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                    AGE ANALYSIS OF PARTICIPATE
                </div>
                <div className='items-start justify-start col-span-2 mt-2 border border-gray-900 '>
                    <AgeGraph />
                </div>
            </div>
            <div>
                <div className='grid mt-1 md:grid-cols-3 gap-x-1'>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            SERVICE LENGTH ANALYSIS
                        </div>
                        <div className='items-start justify-start col-span-2 mt-2 border border-gray-900 '>
                            <ServiceGraph />
                        </div>
                    </div>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            BPS ANALAYSIS
                        </div>
                        <div className='flex items-center justify-center col-span-2 mt-2 border border-gray-900 '>
                            <BpsGraph />
                        </div>
                    </div>
                    <div>
                        <div className='text-xl font-bold text-center text-black bg-green-200 border-gray-900'>
                            QUALIFICATIONS
                        </div>
                        <div className='items-start justify-start col-span-2 mt-2 border border-gray-900 '>
                            <QualificationGraph />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;


// const Dashboard = () => {
//     const dispatch = useDispatch();
//     const userInfo = useSelector(state => state.userInfo);
//     const UserRole = userInfo.role;
//     const backendBaseUrl = config.backendLocalhost;

//     const [dashboard, setDashboard] = useState({
//         No_Stud: {
//             Total: 0,
//             Active: 0
//         },
//         No_Faculty: {
//             Total: 0,
//             Active: 0
//         },
//         ELearning_Material: {
//             Total: 0,
//             EBooks: 0,
//             Audio: 0,
//             Video: 0
//         },
//         studentsPerCourse: [],
//         facultyCourses: []
//     });

//     const [dashboardValues, setDashboardValues] = useState({
//         selectedDept: [],
//         selectedProg: [],
//         selectedCourse: [],
//     });
//     const [allDepartment, setAllDepartment] = useState([]);
//     const [allCourses, setAllCourse] = useState([]);
//     const [allPrograms, setAllPrograms] = useState([]);
//     const [loadingDataToogle, setLoadingDataToogle] = useState({
//         fetchDept: false,
//         fetchProg: false,
//         fetchCourse: false,
//     });

//     const fetchDepartments = async () => {
//         setLoadingDataToogle(prevState => ({
//             ...prevState,
//             fetchDept: false
//         }));
//         try {
//             const user = {
//                 userID:userInfo._id,
//                 userRole:userInfo.role,
//               }
//             const AllDepartment = await Apis.getAllDepartment("",user);
//             dispatch(setAllDept(AllDepartment || []));
//             setAllDepartment(AllDepartment);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchDept: true
//             }));
//         } catch (error) {
//             console.error('Error fetching departments:', error.message);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchDept: true
//             }));
//         }
//     };
//     const fetchProgram = async () => {
//         setLoadingDataToogle(prevState => ({
//             ...prevState,
//             fetchProg: false
//         }));
//         try {
//             const response = await axios.get(`${backendBaseUrl}/executiveDashboard/getProgram`, {
//                 params: dashboardValues
//             });
//             const AllProgram = response.data;
//             setAllPrograms(AllProgram);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchProg: true
//             }));
//         } catch (error) {
//             console.error('Error fetching Program:', error.message);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchProg: true
//             }));
//         }
//     };
//     const fetchCourses = async () => {
//         setLoadingDataToogle(prevState => ({
//             ...prevState,
//             fetchCourse: false
//         }));
//         try {
//             const response = await axios.get(`${backendBaseUrl}/executiveDashboard/getCourse`, {
//                 params: dashboardValues
//             });
//             const AllCourses = response.data;
//             setAllCourse(AllCourses);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchCourse: true
//             }));
//         } catch (error) {
//             console.error('Error fetching Courses:', error.message);
//             setLoadingDataToogle(prevState => ({
//                 ...prevState,
//                 fetchCourse: true
//             }));
//         }
//     };

//     useEffect(() => {
//         fetchDepartments();
//         setDashboardValues(prevState => ({
//             ...prevState,
//             selectedDept: []
//         }));
//     }, []);
//     useEffect(() => {
//         fetchProgram();
//         setDashboardValues(prevState => ({
//             ...prevState,
//             selectedProg: []
//         }));
//     }, [dashboardValues.selectedDept]);
//     useEffect(() => {
//         fetchCourses();
//         setDashboardValues(prevState => ({
//             ...prevState,
//             selectedCourse: []
//         }));
//     }, [dashboardValues.selectedProg]);

//     const handleDepartmentSelection = (selectedDepartments) => {
//         setDashboardValues((prevState) => ({
//             ...prevState,
//             selectedDept: selectedDepartments,
//         }));
//     };

//     const handlProgramSelection = (selectedPrograms) => {
//         setDashboardValues((prevState) => ({
//             ...prevState,
//             selectedProg: selectedPrograms,
//         }));
//     };

//     const handleCourseSelection = (selectedCourses) => {
//         setDashboardValues((prevState) => ({
//             ...prevState,
//             selectedCourse: selectedCourses,
//         }));
//     };
    
//     const GetDashboardValues = async (dashboardValues) => {
//         try {
//             const response = await axios.get(`${backendBaseUrl}/executiveDashboard/dashboardValues`, {
//                 params: dashboardValues
//             });
//             setDashboard(response.data);
//         } catch (error) {
//             console.log("Error Occured", error);
//         }
//     };

//     useEffect(() => {
//         GetDashboardValues(dashboardValues);
//     }, [dashboardValues]);

//     useEffect(() => {
//         setDashboardValues(prevState => ({
//             ...prevState,
//             selectedDept: [userInfo.department]
//         }));
//     }, [userInfo.department]);

//     return (
//         <div className='flex flex-col w-full h-full overflow-auto bg-blue-100 xl:flex-row'>
//             <div className={`xl:w-[30%] xl:max-h-full w-full h-auto  ${userInfo.role === "ExcutiveManagement" ? "flex flex-col" : "grid grid-rows-3"} gap-y-4 py-4 pl-4 bg-transparent `}>
//                 {UserRole !== "ExcutiveManagement" ?
//                     <CheckBoxesList
//                         ArrData={allDepartment}
//                         title={"Select Department"}
//                         name={"department"}
//                         code={"deptCode"}
//                         onSelectionChange={handleDepartmentSelection}
//                         loading={loadingDataToogle.fetchDept}
//                     />
//                     :
//                     <div className="w-full h-[10%] flex flex-col justify-center items-center  text-md font-bold tracking-wider bg-PrimaryColor text-white -xl">
//                         Department: {userInfo.department}
//                     </div>
//                 }
//                 <CheckBoxesList
//                     ArrData={allPrograms}
//                     title={"Select Program"}
//                     name={"programName"}
//                     code={"programCode"}
//                     onSelectionChange={handlProgramSelection}
//                     loading={loadingDataToogle.fetchProg}
//                 />
//                 <CheckBoxesList
//                     ArrData={allCourses}
//                     title={"Select Course"}
//                     name={"courseName"}
//                     code={"courseCode"}
//                     onSelectionChange={handleCourseSelection}
//                     loading={loadingDataToogle.fetchCourse}
//                 />
//             </div>
//             <div className='xl:w-[70%] h-full flex flex-col gap-y-4 w-full '>
//                 <div className='xl:w-full xl:h-[25%] h-auto w-full grid grid-rows-4 gap-x-4 p-4 xl:grid-cols-4 xl:grid-rows-1'>
//                     <Cards
//                         Title={"No. of students"}
//                         heading1={"Registred"}
//                         val1={dashboard.No_Stud.Total}
//                         heading2={"Active"}
//                         val2={dashboard.No_Stud.Active}
//                     />
//                     <Cards
//                         Title={"Faculty Members"}
//                         heading1={"Registred"}
//                         val1={dashboard.No_Faculty.Total}
//                         heading2={"Active"}
//                         val2={dashboard.No_Faculty.Active}
//                     />
//                     <Cards
//                         Title={"Learning Material"}
//                         heading1={"Videos"}
//                         val1={dashboard.ELearning_Material.Video}
//                         heading2={"Audios"}
//                         val2={dashboard.ELearning_Material.Audio}
//                         heading3={"Documents"}
//                         val3={dashboard.ELearning_Material.EBooks}
//                     />
//                     <div className='flex flex-row w-full h-full gap-y-2 xl:flex-col'>
//                         <div className='flex items-center w-full p-2 text-sm text-center text-white -lg h-1/3 jusitfy-start bg-PrimaryDarkColor'>
//                             <p className='text-start w-[80%]'>Departments</p>
//                             <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedDept.length}</p>
//                         </div>
//                         <div className='flex items-center w-full p-2 text-sm text-center text-white rounded-lg h-1/3 jusitfy-start bg-PrimaryDarkColor'>
//                             <p className='text-start w-[80%]'>Programs</p>
//                             <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedProg.length}</p>
//                         </div>
//                         <div className='flex items-center w-full p-2 text-sm text-center text-white rounded-lg h-1/3 jusitfy-start bg-PrimaryDarkColor'>
//                             <p className='text-start w-[80%]'>Courses</p>
//                             <p className='text-start font-bold w-[20%]'>{dashboardValues.selectedCourse.length}</p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='xl:w-full xl:h-[75%] xl:flex flex-row h-full w-full  '>
//                     <div className='xl:w-[55%] py-4 px-1'>
//                         <BarGraph data={dashboard.facultyCourses} />
//                     </div>
//                     <div className='xl:w-[45%] py-4 px-1'>
//                         <PieCharts
//                             data={dashboard.studentsPerCourse}
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }
// export default Dashboard