import React from 'react'
import Switcher from '../../Switcher'
const CandidateRegistration = () => {
    return (
        <div className='h-full p-1 overflow-y-auto bg-gray-300'>
            <div className='px-1 text-xl text-white bg-green-600'>
                Candidate Registration
            </div>
            <div className='px-1 mt-1 text-lg text-white bg-blue-900 md:flex gap-x-3'>
                <h1>Name: XXXXXXXXXXXXXXX,</h1>
                <h1>CNIC: XXXXX-XXXXXXX-X,</h1>
                <h1>Mobile: XXXX-XXXXXXX,</h1>
                <h1>E-Mail: XXXXXXXXXXXXXXXXX</h1>
            </div>
            <div className='mt-1'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    A. Course Particulars
                </div>
                <div className='mt-1 text-black md:flex gap-x-5'>
                    <div className="flex flex-col w-full md:ml-5">
                        <label htmlFor="deptCode" className="text-sm font-semibold">
                            1) Progarm
                        </label>
                        <input
                            type="text"
                            placeholder=" "
                            className="w-full font-normal bg-white input outline-PrimaryColor"
                        />
                    </div>
                    <div className="flex flex-col w-full">
                        <label htmlFor="deptCode" className="text-sm font-semibold">
                            2) Course No
                        </label>
                        <input
                            type="text"
                            placeholder=" "
                            className="w-full font-normal bg-white input outline-PrimaryColor"
                        />
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    B. Employment Data
                </div>
                <div className='w-full md:flex gap-x-5'>
                    <div className='w-full mt-1 text-black md:ml-5'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                1) Name
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                2) Father's Name
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                3) Date of Birth
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                4) Gender
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                5) Domicile - Province
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                6) Domicile - District
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                    <div className='w-full mt-1 text-black'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                9) Qualification
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                10) Nationality
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                9) Religion
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                10) Home Adress - Line-1
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col ml-5">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                Home Adress - Line-2
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white rounded-lg input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col ml-5">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                Home Adress - District
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex items-start justify-end mt-2 '>
                    <button className='p-2 text-base bg-blue-900 rounded-lg'>
                        Save & Next
                    </button>
                </div>
            </div>
            <div className='mt-2'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    C. Employment Data
                </div>
                <div className='w-full md:flex gap-x-5'>
                    <div className='w-full mt-1 text-black md:ml-5'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                1) Date of Initial Appointment
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                2) Grade of Initial Apponiment
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                3) Present Grade/ BPS
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                4) Date of Entry in Present Grade/ BPS
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                5) Basic Pay
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                6) Official Phone No.
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                7) Official Fax No.
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                8) Official Postal Address
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                    <div className='w-full mt-1 text-black'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                9) Present Designation
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full py-1 md:py-6">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                10) Present Employer
                            </label>
                        </div>
                        <div className="flex flex-col ml-5 ">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                a) Formation
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col ml-5">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                b) Department/ Company
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col ml-5">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                DDO Name
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col ml-5">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                DDO Address
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                11) Name of Immediate Supervisor
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                12) Designation of Immediate Supervisor
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex items-start justify-end mt-2 '>
                    <button className='p-2 text-base bg-blue-900 rounded-lg'>
                        Save & Next
                    </button>
                </div>
            </div>
            <div className='mt-2'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    C. Emergency Contact
                </div>
                <div className='w-full md:flex gap-x-5'>
                    <div className='w-full mt-1 text-black md:ml-5'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                1) Name
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                2) Relationship
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                    <div className='w-full mt-1 text-black'>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                3) Mobile No.
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                        <div className="flex flex-col w-full">
                            <label htmlFor="deptCode" className="text-sm font-semibold">
                                4) Phone No.
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-full font-normal bg-white input outline-PrimaryColor"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex items-start justify-end mt-2'>
                    <button className='p-2 text-base bg-blue-900 rounded-lg'>
                        Save & Next
                    </button>
                </div>
            </div>
            <div className='mt-2'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    D. Accommodation Details
                </div>
                <div className='flex w-full md:gap-x-5'>
                    <div className='w-full mt-1 text-black md:flex md:ml-5'>
                        <div className="flex w-full gap-x-10 md:gap-x-0">
                            <label htmlFor="" className="text-sm font-semibold">
                                1) Is Hostel Accommodation Availed
                            </label>
                            <div className='w-[30%]'>
                                <Switcher />
                            </div>
                        </div>
                        <div className="flex w-full gap-x-10 md:gap-x-0">
                            <label htmlFor="deptCode" className="text-sm font-semibold md:w-[90%] md:ml-5">
                                If Yes, then Hostel Room No.
                            </label>
                            <input
                                type="text"
                                placeholder=" "
                                className="w-[25%] font-normal bg-white input outline-PrimaryColor "
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-2'>
                <div className='px-1 text-xl text-white bg-green-600'>
                    E. Upload Documents/ Images
                </div>
               <div>
                <div className='flex justify-between mt-1 text-black md:ml-5 gap-x-5'>
                    <label htmlFor="" className="text-sm font-semibold w-[25%]">
                        1) CNIC
                    </label>
                    <input
                        type="text"
                        placeholder=" "
                        className="font-normal bg-white input outline-PrimaryColor w-[25%]"
                    />
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Select File
                    </label>
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Upload
                    </label>
                    <div className='flex items-start justify-end w-[30%]'>
                        <button className='w-full p-2 text-white bg-blue-900 rounded-lg md:text-base'>
                        View CNIC
                        </button>
                    </div>
                </div>
                <div className='flex justify-between mt-1 text-black md:ml-5 gap-x-5'>
                    <label htmlFor="" className="text-sm font-semibold w-[25%]">
                        2) Copy of Relieving Orders
                    </label>
                    <input
                        type="text"
                        placeholder=" "
                        className="font-normal bg-white input outline-PrimaryColor w-[25%]"
                    />
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Select File
                    </label>
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Upload
                    </label>
                    <div className='flex items-start justify-end w-[30%]'>
                        <button className='w-full text-white bg-blue-900 rounded-lg md:p-2 md:text-base'>
                            View Relieving Orders
                        </button>
                    </div>
                </div>
                <div className='flex justify-between mt-1 text-black md:ml-5 gap-x-5'>
                    <label htmlFor="" className="text-sm font-semibold w-[25%]">
                        3) Picture/Photograph
                    </label>
                    <input
                        type="text"
                        placeholder=" "
                        className="font-normal bg-white input outline-PrimaryColor w-[25%]"
                    />
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Select File
                    </label>
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Upload
                    </label>
                    <div className='flex items-start justify-end w-[30%]'>
                        <button className='w-full text-white bg-blue-900 rounded-lg md:p-2 md:text-base'>
                            View Picture/ Photograph
                        </button>
                    </div>
                </div>
                <div className='flex justify-between mt-1 text-black md:ml-5 gap-x-5'>
                    <label htmlFor="" className="text-sm font-semibold w-[25%]">
                        4) Covid-19 Vaccination Certificate
                    </label>
                    <input
                        type="text"
                        placeholder=" "
                        className="font-normal bg-white input outline-PrimaryColor w-[25%]"
                    />
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Select File
                    </label>
                    <label htmlFor="deptCode" className="text-sm font-semibold text-blue-500 underline w-[10%]">
                        Upload
                    </label>
                    <div className='flex items-start justify-end w-[30%]'>
                        <button className='w-full text-white bg-blue-900 rounded-lg md:p-2 md:text-base'>
                            View Covid-19 Vaccination Certificate
                        </button>
                    </div>
                </div>
               </div>
            </div>
            <p className='w-full p-0.5 mt-1 bg-orange-400 border'></p>
            <div className='justify-between mt-1 text-black md:flex md:ml-5 gap-x-5'>
                    <label htmlFor="" className="text-base font-semibold md:w-[50%] text-red-500">
                        Note: After Submitting, this form will be locked for editing
                    </label>
                   
                    <div className='flex items-start justify-end md:w-[50%] gap-x-5'>
                        <button className='w-full p-2 text-base text-white bg-blue-900 rounded-lg'>
                            CLEAR
                        </button>
                        <button className='w-full p-2 text-base text-white bg-blue-900 rounded-lg'>
                            PRINT
                        </button>
                        <button className='w-full p-2 text-base text-white bg-blue-900 rounded-lg'>
                            SAVE
                        </button>
                        <button className='w-full p-2 text-base text-white bg-blue-900 rounded-lg'>
                            SUBMIT
                        </button>
                    </div>
                </div>
            <p className='w-full p-0.5 mt-1 bg-orange-400 border'></p>
        </div>
    )
}

export default CandidateRegistration
