import './App.css';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConfigName, setResponseMessage } from './state/action-creators';
import { Route, Routes } from "react-router-dom";
import Signup from "./pages/components/Signup";
import Faculty from './pages/FacultyDashboard'
import Dashboard from './components/StudentDashboard/Dashboard'
import Admin from './pages/Admin';
import Login from "./pages/components/Login";
import PrivateRoute from "./Private_Route/PrivateRoute"
import Profile from './components/Profile';
import AuditTrail from './components/AuditTrail';
import Room from './components/Room';
import ForgetPassword from './pages/components/ForgetPassword/ForgetPassword';
import Apis from './Apis';
import ExecutiveAdmin from './pages/ExecutiveAdmin';
import ExecutiveManagement from './pages/ExecutiveManagement';
import { WebSocketComponent } from './WebSocketComponent';

function App() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.userInfo);

  const fetchData = useCallback(async () => {
    try {
      const [configName, responseMsg] = await Promise.all([
        Apis.getConfigureName(),
        Apis.fetchAllRepsonseMessages(null),
      ]);
      dispatch(setConfigName(configName || []));
      dispatch(setResponseMessage(responseMsg || []));
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {user && user._id && <WebSocketComponent user={user} />}
      <Routes>
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/" exact element={<Login />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/Profile"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/AuditTrail"
          element={
            <PrivateRoute requiredRole={"admin"}>
              <AuditTrail />
            </PrivateRoute>
          }
        />
        <Route
          path="/FacultyDashboard"
          element={
            <PrivateRoute requiredRole={"faculty"}>
              <Faculty />
            </PrivateRoute>
          } />
        <Route
          path="faculty/Profile"
          element={
            <PrivateRoute requiredRole={"faculty"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="faculty/AuditTrail"
          element={
            <PrivateRoute requiredRole={"faculty"}>
              <AuditTrail />
            </PrivateRoute>
          }
        />
        <Route
          path="/FacultyDashboard/room/:roomID"
          element={
            <PrivateRoute requiredRole={"faculty"}>
              <Room />
            </PrivateRoute>
          }
        />
        <Route
          path="/StudentDashboard"
          element={
            <PrivateRoute requiredRole={"student"}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="student/Profile"
          element={
            <PrivateRoute requiredRole={"student"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="student/AuditTrail"
          element={
            <PrivateRoute requiredRole={"student"}>
              <AuditTrail />
            </PrivateRoute>
          }
        />
        <Route
          path="StudentDashboard/room/:roomID"
          element={
            <PrivateRoute requiredRole={"student"}>
              <Room />
            </PrivateRoute>
          }
        />
        <Route
          path="/ExcutiveAdmin"
          element={
            <PrivateRoute requiredRole={"ExcutiveAdmin"}>
              <ExecutiveAdmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/ExcutiveAdmin/profile"
          element={
            <PrivateRoute requiredRole={"ExcutiveAdmin"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="ExcutiveAdmin/AuditTrail"
          element={
            <PrivateRoute requiredRole={"ExcutiveAdmin"}>
              <AuditTrail />
            </PrivateRoute>
          }
        />
        <Route
          path="/ExcutiveManagement"
          element={
            <PrivateRoute requiredRole={"ExcutiveManagement"}>
              <ExecutiveManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/ExcutiveManagement/profile"
          element={
            <PrivateRoute requiredRole={"ExcutiveManagement"}>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="ExcutiveManagement/AuditTrail"
          element={
            <PrivateRoute requiredRole={"ExcutiveManagement"}>
              <AuditTrail />
            </PrivateRoute>
          }
        />
        <Route path="*" exact element={<div>Page Not Found!</div>} />
      </Routes>
    </>

  );
}

export default App;