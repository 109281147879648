import React from 'react';
import EditButton from '../EditButton';
import { useSelector } from 'react-redux';


const ViewDepartment = ({ setUpdateDept, handleButtonClick }) => {
  const departments = useSelector(state => state.allDept);

  const handleEditDepartment = (dept) => {
    setUpdateDept(dept);
    handleButtonClick('create');
  };

  return (
    <div className='flex flex-col w-full h-auto overflow-x-auto bg-gray-300 border border-gray-900 rounded-b-lg style-scrollbar lg:max-h-full lg:overflow-auto max-h-auto'>
      <table className="table min-w-full text-black rounded-lg table-auto bg-SecondaryColor" style={{ width: '100%' }}>
        <thead className=''>
          <tr className='text-lg font-semibold text-black'>
            <th className="p-2 text-center border border-gray-400">Sr.</th>
            <th className="p-2 text-left border border-gray-400">Name</th>
            <th className="p-2 text-left border border-gray-400">Department Code</th>
            <th className="p-2 text-left border border-gray-400">Email</th>
            <th className="p-2 text-left border border-gray-400">Contact No</th>
            <th className="p-2 text-left border border-gray-400">Website</th>
            <th className="p-2 text-left border border-gray-400">Location</th>
            <th className="p-2 text-left border border-gray-400">Address</th>
            <th className="p-2 text-center border border-gray-400">Status</th>
            <th className="p-2 text-center border border-gray-400">Actions</th>
          </tr>
        </thead>
        <tbody>
          {departments.map((dept, index) => (
            <tr className={`${index % 2 === 0 ? "bg-white text-lg" : "bg-gray-100 text-lg"}`} key={index}>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">{index + 1}</td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">{dept.department}</td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">{dept.deptCode}</td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">{dept.email}</td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">{dept.contact}</td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">
                <a
                  className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.website ? "" : "hidden"}`}
                  href={dept.website}
                  target="_blank"
                  rel="noopener noreferrer">
                  {"Click here"}
                </a>
              </td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">
                <a
                  className={`cursor-pointer hover:text-blue-600 hover:underline duration-300 ${dept.location ? "" : "hidden"}`}
                  href={dept.location}
                  target="_blank"
                  rel="noopener noreferrer">
                  {"Click here"}
                </a>
              </td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">
                {dept.address}
              </td>
              <td className={`border-x border-gray-400 p-2 whitespace-nowrap text-left ${dept.status ? "text-green-600" : "text-red-600"}`}> {dept.status ? "Active" : "In-Active"} </td>
              <td className="p-2 text-left border-gray-400 border-x whitespace-nowrap">
                <button onClick={() => handleEditDepartment(dept)}>
                  <EditButton />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewDepartment