import React from "react";
import { Chart } from "react-google-charts";

const data = [
  ["Gender", "Number"],
  ["Male , 198", 198],
  ["Female , 65", 65],
];

const options = {
  legend: {
    position: "none", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    textStyle: { fontSize: 15 }, 
  },
  pieHole: 0.4,
  is3D: false,
  pieSliceText: "label",
  pieSliceTextStyle: {
    fontSize: 11, 
    bold: true,
  },
  pieStartAngle: 100,
  tooltip: { trigger: "focus" },
  chartArea: { width: "70%", height: "70%" },
  colors: ["#426631", "#7fc25f"],
};

function GenderGraph() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"365px"}
    />
  );
}

export default GenderGraph;
