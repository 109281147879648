import React from 'react';
import ReactApexChart from 'react-apexcharts';

class AgeGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Inflation',
          data: [2, 3, 4, 7, 4, 3, 3, 2, 4, 8, 5, 2,
            3, 1, 4, 8, 4, 6, 2, 3, 10, 8, 5, 2,
            2, 3, 4, 1, 4, 6, 2, 3, 4, 9,],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            },
          },
        },
        colors: ['#3181b0'],
        dataLabels: {
          enabled: true,
          offsetY: -30,
          style: {
            fontSize: '18px', 
            colors: ['#304758'],
            fontWeight: 'bold',
          },
        },
        xaxis: {
          categories: [
            '< 22 Years', '23 Years', '24 Years', '25 Years', '26 Years',
            '27 Years', '28 Years', '29 Years', '30 Years', '31 Years', '32 Years', 
            '33 Years', '34 Years', '35 Years', '36 Years', '37 Years', '38 Years',
           '39 Years', '40 Years', '41 Years', '42 Years', '43 Years', '44 Years'
           , '45 Years', '46 Years', '47 Years', '48 Years', '49 Years', '50 Years'
           , '51 Years', '52 Years', '53 Years', '54 Years', '> 55 Years'
          ],
          position: 'bottom',
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: {
            style: {
              fontSize: '17px',
              colors: ['#333'], 
              fontWeight: 'bold',
            },
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: { enabled: true },
        },
        yaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: {
            show: true,
            formatter: (val) => `${val}%`,
            style: {
              fontSize: '0px',
              colors: ['#fff'],
              fontWeight: 'bold',
            },
          },
        },
        title: {
          floating: true,
          offsetY: 330,
          align: 'center',
        },
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <ReactApexChart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default AgeGraph;
