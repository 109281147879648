import React from 'react';

const Switcher = ({ id, func, enabled }) => {
    return (
        <div>
            <label htmlFor={`toggle-${id}`} className="flex items-center cursor-pointer select-none">
                <div className="relative">
                    <input
                        type="checkbox"
                        id={`toggle-${id}`}
                        className="sr-only"
                        onChange={func}
                    />
                    <div className={`block h-8 w-20 rounded-full ${enabled ? "bg-green" : "bg-gray"}`}></div>
                    <div className={`z-10 absolute top-1 flex h-6 w-6 items-center justify-center rounded-full duration-300 ${!enabled ? "translate-x-12" : "left-1"} transition`}>
                        <p className={`font-normal text-sm ${enabled ? "text-white" : "text-black"}`}>{enabled ? "Yes" : "No"}</p>
                    </div>
                    <div className={`absolute top-1 flex h-6 w-6 items-center justify-center rounded-full duration-300 ${enabled ? "bg-greenGradient translate-x-12" : "bg-grayGradient left-1"} transition`}></div>
                </div>
            </label>
        </div>
    );
};

export default Switcher;
