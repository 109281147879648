import React from "react";
import { Chart } from "react-google-charts";

const data = [
  ["Name", "Percentage"],
  ["JOINED", 0.45],
  ["NON JOINED", 0.20],
  ["COMPLETED", 0.29],
  ["U/TRAINING", 0.25],
];

const options = {
  chartArea: { width: "100%", height: "90%" },
  pieHole: 0,
  colors: ["#e3d736", "#bd5c46", "#389c5d", "#ac5dc9"],
  fontSize: 12, 
  legend: {
    position: 'start',
    alignment: 'center',
    fontWeight: 'bold',
  },
  pieSliceText: 'value', 
  pieSliceTextStyle: {
    fontSize: 14, 
    color: '#fff',
    fontWeight: 'bold',
  },
};

const chartContainerStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
  height: "100%",
  fontWeight: 'bold',
};

function FullPieGraph() {
  return (
    <div style={{ height: "130px", width: "100%" }}>
      <div style={chartContainerStyle}>
        <Chart
          chartType="PieChart"
          width="100%"
          height="130px"
          data={data}
          options={options}
        />
      </div>
    </div>
  );
}

export default FullPieGraph;
